import endpoints from './endpoints.js'
import axios from 'axios'
import { getCookie } from './../utils.js'

let store = null;

function getTokens() {
    return getCookie("tokens")
}

async function tryRefreshToken() {
    const tokens = getTokens()

    if (tokens && (new Date(tokens.expiration) - new Date()) / 1000 / 60 < 1) {
        await store.dispatch("refreshToken")
    }
}

async function request(endpoint, method, content, additionalHeaders, uploadProgressFunc) {
    let response = null
    try {
        await tryRefreshToken()

        switch (method) {
            case "POST":
                response = await post(endpoint, content, getConfig(additionalHeaders, uploadProgressFunc))
                break;
            case "GET":
                response = await get(endpoint, getConfig(additionalHeaders, uploadProgressFunc))
                break;
            case "DELETE":
                response = await deleteRequest(endpoint, getConfig(additionalHeaders, uploadProgressFunc))
                break;
        } 
    } catch (exception) {
        console.log("Requests exception:", exception)
    }

    return response
        ? response.data
        : {
            isSuccess: false
        }
}

function getConfig(additionalHeaders, uploadProgressFunc) {
    const tokens = getTokens()

    let config = {
        headers: { }
    }

    if (tokens) {
        config = {
            headers: {
                "Authorization": "Bearer " + tokens.token
            }
        }
    }

    if (additionalHeaders) {
        config.headers = { ...config.headers, ...additionalHeaders }
    }

    if (uploadProgressFunc) {
        config.onUploadProgress = (progressEvent) => uploadProgressFunc(progressEvent)
    }

    return config
}

async function get(endpoint, config) {
    return await axios.get(endpoint, config)
}

async function post(endpoint, content, config) {
    return await axios.post(endpoint, content, config)
}

async function deleteRequest(endpoint, config) {
    return await axios.delete(endpoint, config)
}

export default {
    initialize(inputStore) {
        store = inputStore
    },
    async tryRefreshToken() {
        await tryRefreshToken()
    },
    UserAccess: {
        UserRegistrations: {
            async Register(username, email, password, gender, isCreator, confirmationLink) {
                return await request(endpoints.UserAccess.UserRegistrations.Register(), "POST", {
                    username: username,
                    email: email,
                    password: password,
                    gender: gender,
                    isCreator: isCreator,
                    confirmationLink: confirmationLink
                })
            },
            async RegisterWithThirdParty(thirdParty, state, username, email, gender, isCreator) {
                return await request(endpoints.UserAccess.UserRegistrations.RegisterWithThirdParty(), "POST", {
                    thirdParty: thirdParty,
                    state: state,
                    username: username,
                    email: email,
                    gender: gender,
                    isCreator: isCreator
                })
            },
            async Confirm(registrationId) {
                return await request(endpoints.UserAccess.UserRegistrations.Confirm(registrationId), "POST")
            }
        },
        Users: {
            async GetUserById(id) {
                return await request(endpoints.UserAccess.Users.GetUserById(id), "GET")
            },
            async LookupByIds(ids) {
                return await request(endpoints.UserAccess.Users.LookupByIds(), "POST", {
                    ids: ids
                })
            },
            async BanUserById(id, reason) {
                return await request(endpoints.UserAccess.Users.BanUserById(id), "POST", {
                    reason: reason
                })
            },
            async ConnectWithThirdPartyByState(userId, thirdParty, state) {
                return await request(endpoints.UserAccess.Users.ConnectWithThirdPartyByState(userId, thirdParty, state), "POST")
            }
        },
        PasswordResets: {
            async CreatePasswordReset(email, resetLink) {
                return await request(endpoints.UserAccess.PasswordResets.CreatePasswordReset(), "POST", {
                    email: email,
                    resetLink: resetLink
                })
            },
            async ConfirmPasswordReset(passwordResetId, newPassword, confirmPassword) {
                return await request(endpoints.UserAccess.PasswordResets.ConfirmPasswordReset(passwordResetId), "POST", {
                    newPassword: newPassword,
                    confirmPassword: confirmPassword
                })
            }
        }
    },
    UserContent: {
        UserProfiles: {
            async GetUserProfileById(profileId) {
                return await request(endpoints.UserContent.UserProfiles.GetUserProfileById(profileId), "GET")
            },
            async GetUserProfileByUserId(userId) {
                return await request(endpoints.UserContent.UserProfiles.GetUserProfileByUserId(userId), "GET")
            },
            async GetUserProfileByHandle(handle) {
                return await request(endpoints.UserContent.UserProfiles.GetUserProfileByHandle(handle), "GET")
            },
            async GetUserProfileByThirdPartyByHandle(thirdParty, handle) {
                return await request(endpoints.UserContent.UserProfiles.GetUserProfileByThirdPartyByHandle(thirdParty, handle), "GET")
            },
            async ChangeUserProfileSettings(profileId, settings) {
                return await request(endpoints.UserContent.UserProfiles.ChangeUserProfileSettings(profileId), "POST", {
                    name: settings.name,
                    handleName: settings.handleName,
                    description: settings.description,
                    isPublic: settings.isPublic,
                    socials: settings.socials
                })
            },
            async GetFollowsByProfileId(profileId) {
                return await request(endpoints.UserContent.UserProfiles.GetFollowsByProfileId(profileId), "GET")
            },
            async FollowProfile(profileId) {
                return await request(endpoints.UserContent.UserProfiles.FollowProfile(profileId), "POST")
            },
            async UnfollowProfile(profileId) {
                return await request(endpoints.UserContent.UserProfiles.UnfollowProfile(profileId), "POST")
            },
            async GetHomeContent(profileId, skip, take) {
                return await request(endpoints.UserContent.UserProfiles.GetHomeContent(profileId, skip, take), "GET")
            },
            async GetUserProfileCount() {
                return await request(endpoints.UserContent.UserProfiles.GetUserProfileCount(), "GET")
            },
            async LookupByUserIds(userIds) {
                return await request(endpoints.UserContent.UserProfiles.LookupByUserIds(), "POST", {
                    userIds: userIds
                })
            },
            async GetAllUserProfiles(skip, take) {
                return await request(endpoints.UserContent.UserProfiles.GetAllUserProfiles(skip, take), "GET")
            },
            async CreateThirdPartyProfile(thirdParty, username, profileName, defaultGenderPreferenceId) {
                return await request(endpoints.UserContent.UserProfiles.CreateThirdPartyProfile(thirdParty), "POST", {
                    username: username,
                    profileName: profileName,
                    defaultGenderPreferenceId: defaultGenderPreferenceId
                })
            }
        },
        Audio: {
            async UploadAudio(name, description, fileName, contentType, tagIds, isPublic) {
                return await request(endpoints.UserContent.Audio.UploadAudio(), "POST", {
                    name: name,
                    description: description,
                    fileName: fileName,
                    contentType: contentType,
                    genderPreferenceIds: tagIds.genderPreference,
                    categoryIds: tagIds.category,
                    tagIds: tagIds.tag,
                    isPublic: isPublic
                })
            },
            async EditAudio(audioId, name, description, tagIds, isPublic, isEditingLocked) {
                return await request(endpoints.UserContent.Audio.EditAudio(audioId), "POST", {
                    name: name,
                    description: description,
                    genderPreferenceIds: tagIds.genderPreference,
                    categoryIds: tagIds.category,
                    tagIds: tagIds.tag,
                    isPublic: isPublic,
                    isEditingLocked: isEditingLocked
                })
            },
            async GetAudioFileUrl(audioId) {
                return await request(endpoints.UserContent.Audio.GetAudioFileUrl(audioId), "GET")
            },
            async GetThirdPartyPageUrl(audioId) {
                return await request(endpoints.UserContent.Audio.GetThirdPartyPageUrl(audioId), "GET")
            },
            async GetAudioByIdIncludeProfile(audioId) {
                return await request(endpoints.UserContent.Audio.GetAudioByIdIncludeProfile(audioId), "GET")
            },
            async GetAudioStatusById(audioId) {
                return await request(endpoints.UserContent.Audio.GetAudioStatusById(audioId), "GET")
            },
            async GetAudiosByProfileIdSkipTake(profileId, skip, take) {
                return await request(endpoints.UserContent.Audio.GetAudiosByProfileIdSkipTake(profileId, skip, take), "GET")
            },
            async DeleteAudio(audioId) {
                return await request(endpoints.UserContent.Audio.DeleteAudio(audioId), "DELETE")
            },
            async UpvoteAudio(audioId) {
                return await request(endpoints.UserContent.Audio.UpvoteAudio(audioId), "POST")
            },
            async UnvoteAudio(audioId) {
                return await request(endpoints.UserContent.Audio.UnvoteAudio(audioId), "POST")
            },
            async ListenAudio(audioId) {
                return await request(endpoints.UserContent.Audio.ListenAudio(audioId), "POST")
            },
            async GetCommentsByAudioId(audioId) {
                return await request(endpoints.UserContent.Audio.GetCommentsByAudioId(audioId), "GET")
            },
            async CreateComment(audioId, text, replyToCommentId) {
                return await request(endpoints.UserContent.Audio.CreateComment(audioId), "POST", {
                    replyToCommentId: replyToCommentId,
                    text: text
                })
            },
            async DeleteComment(audioId, commentId) {
                return await request(endpoints.UserContent.Audio.DeleteComment(audioId, commentId), "DELETE")
            },
            async ForgetAllSasTokens() {
                return await request(endpoints.UserContent.Audio.ForgetAllSasTokens(), "POST")
            },
            async TotalListenCount(profileId) {
                return await request(endpoints.UserContent.Audio.TotalListenCount(profileId), "GET")
            },
            async TotalUpvoteCount(profileId) {
                return await request(endpoints.UserContent.Audio.TotalUpvoteCount(profileId), "GET")
            },
            async Count(profileId) {
                return await request(endpoints.UserContent.Audio.Count(profileId), "GET")
            },
            async LookupByIds(audioIds) {
                return await request(endpoints.UserContent.Audio.LookupByIds(), "POST", {
                    audioIds: audioIds
                })
            }
        },
        SearchContent: {
            async SearchSkipTake(query, skip, take, selectedTagIds, deselectedTagIds, searchType) {
                return await request(endpoints.UserContent.SearchContent.SearchSkipTake(query, skip, take), "POST", {
                    selectedTagIds: selectedTagIds,
                    deselectedTagIds: deselectedTagIds,
                    searchType: searchType
                })
            },
            async ReindexSearch() {
                return await request(endpoints.UserContent.SearchContent.ReindexSearch(), "POST")
            }
        },
        UserHistory: {
            async GetUserHistoryById(userId) {
                return await request(endpoints.UserContent.UserHistory.GetUserHistoryById(userId), "GET")
            }
        },
        Follow: {
            async Unfollow(followId) {
                return await request(endpoints.UserContent.Follow.Unfollow(followId), "DELETE")
            },
            async IsFollowingProfile(profileId, targetProfileId) {
                return await request(endpoints.UserContent.Follow.IsFollowingProfile(profileId, targetProfileId), "GET")
            }
        },
        Tag: {
            async GetAll() {
                return await request(endpoints.UserContent.Tag.GetAll(), "GET")
            },
            async LookupByIds(tagIds) {
                return await request(endpoints.UserContent.Tag.LookupByIds(), "POST", {
                    tagIds: tagIds
                })
            }
        },
        Report: {
            async CreateReport(audioId, text) {
                return await request(endpoints.UserContent.Report.CreateReport(), "POST", {
                    audioId: audioId,
                    text: text
                })
            },
            async GetPendingReportsSkipTake(skip, take) {
                return await request(endpoints.UserContent.Report.GetPendingReportsSkipTake(skip, take), "GET")
            },
            async ResolveReport(reportId) {
                return await request(endpoints.UserContent.Report.ResolveReport(reportId), "POST")
            }
        },
        Import: {
            async CheckProfileExists(importType, username) {
                return await request(endpoints.UserContent.Import.CheckProfileExists(importType, username), "GET")
            },
            async CreateProfileOwnership(importType, username) {
                return await request(endpoints.UserContent.Import.CreateProfileOwnership(importType, username), "POST")
            },
            async ConfirmProfileOwnership(importType, username) {
                return await request(endpoints.UserContent.Import.ConfirmProfileOwnership(importType, username), "POST")
            },
            async GetImportAudios(importType, username) {
                return await request(endpoints.UserContent.Import.GetImportAudios(importType, username), "GET")
            },
            async BeginImport(importType, username, names, urls, genderPreferenceIds, useAutoTag) {
                return await request(endpoints.UserContent.Import.BeginImport(importType, username), "POST", {
                    names: names,
                    urls: urls,
                    genderPreferenceIds: genderPreferenceIds,
                    useAutoTag: useAutoTag
                })
            },
            async GetImport() {
                return await request(endpoints.UserContent.Import.GetImport(), "GET")
            },
            async GetMostRecentImportTask() {
                return await request(endpoints.UserContent.Import.GetMostRecentImportTask(), "GET")
            }
        }
    },
    Statistics: {
        Charts: {
            async TotalAudioListensByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.TotalAudioListensByTime(audioId, creatorId, from, to), "GET")
            },
            async TotalAudioListenTimeByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.TotalAudioListenTimeByTime(audioId, creatorId, from, to), "GET")
            },
            async TotalAudioListensByWeekdaysByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.TotalAudioListensByWeekdaysByTime(audioId, creatorId, from, to), "GET")
            },
            async TotalAudioListensByHoursByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.TotalAudioListensByHoursByTime(audioId, creatorId, from, to), "GET")
            },
            async MostListenedCreatorsByFromTo(take, from, to) {
                return await request(endpoints.Statistics.Charts.MostListenedCreatorsByFromTo(take, from, to), "GET")
            },
            async MostListenedAudiosByFromTo(creatorId, take, from, to) {
                return await request(endpoints.Statistics.Charts.MostListenedAudiosByFromTo(creatorId, take, from, to), "GET")
            },
            async MostListenedCategoriesByFromTo(creatorId, take, from, to) {
                return await request(endpoints.Statistics.Charts.MostListenedCategoriesByFromTo(creatorId, take, from, to), "GET")
            },
            async ListenerGendersByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.ListenerGendersByTime(audioId, creatorId, from, to), "GET")
            },
            async ListenHeatmap(audioId, lengthSeconds) {
                return await request(endpoints.Statistics.Charts.ListenHeatmap(audioId, lengthSeconds), "GET")
            },
            async SearchOccurenceByTime(audioId, creatorId, from, to) {
                return await request(endpoints.Statistics.Charts.SearchOccurenceByTime(audioId, creatorId, from, to), "GET")
            }
        },
        ListenSession: {
            async ListenToAudio(audioId, seconds) {
                return await request(endpoints.Statistics.ListenSession.ListenToAudio(audioId), "POST", {
                    seconds: seconds
                })
            }
        }
    },
    Monetization: {
        AccountApplication: {
            async GetAllAccountApplications(skip, take) {
                return await request(endpoints.Monetization.AccountApplication.GetAllAccountApplications(skip, take), "GET")
            },
            async GetAccountApplicationByUserId(userId) {
                return await request(endpoints.Monetization.AccountApplication.GetAccountApplicationByUserId(userId), "GET")
            },
            async CreateAccountApplication(userId) {
                return await request(endpoints.Monetization.AccountApplication.CreateAccountApplication(userId), "POST")
            },
            async ApproveAccountApplication(userId) {
                return await request(endpoints.Monetization.AccountApplication.ApproveAccountApplication(userId), "POST")
            },
            async DenyAccountApplication(userId) {
                return await request(endpoints.Monetization.AccountApplication.DenyAccountApplication(userId), "POST")
            }
        },
        Account: {
            async GetAllAccounts(skip, take) {
                return await request(endpoints.Monetization.Account.GetAllAccounts(skip, take), "GET")
            },
            async GetAccountByUserId(userId) {
                return await request(endpoints.Monetization.Account.GetAccountByUserId(userId), "GET")
            },
            async EnableAccountByUserId(userId) {
                return await request(endpoints.Monetization.Account.EnableAccountByUserId(userId), "POST")
            },
            async DisableAccountByUserId(userId) {
                return await request(endpoints.Monetization.Account.DisableAccountByUserId(userId), "POST")
            }
        },
        BillingItem: {
            async GetPriceSums() {
                return await request(endpoints.Monetization.BillingItem.GetPriceSums(), "GET")
            },
            async GetWithoutInvoiceByUserId(userId) {
                return await request(endpoints.Monetization.BillingItem.GetWithoutInvoiceByUserId(userId), "GET")
            },
            async GetWithoutInvoiceByUserIdsGroupByUserId(userIds) {
                return await request(endpoints.Monetization.BillingItem.GetWithoutInvoiceByUserIdsGroupByUserId(), "POST", {
                    userIds: userIds
                })
            }
        },
        InvoiceTemplate: {
            async CreateInvoiceTemplate(userId) {
                return await request(endpoints.Monetization.InvoiceTemplate.CreateInvoiceTemplate(userId), "POST")
            },
            async EditInvoiceTemplate(id, name, billingItemIds) {
                return await request(endpoints.Monetization.InvoiceTemplate.EditInvoiceTemplate(id), "POST", {
                    name: name,
                    billingItemIds: billingItemIds
                })
            },
            async DeleteInvoiceTemplate(id) {
                return await request(endpoints.Monetization.InvoiceTemplate.DeleteInvoiceTemplate(id), "DELETE")
            },
            async GetAllInvoiceTemplatesWithoutInvoice() {
                return await request(endpoints.Monetization.InvoiceTemplate.GetAllInvoiceTemplatesWithoutInvoice(), "GET")
            },
            async GetInvoiceTemplatesById(id) {
                return await request(endpoints.Monetization.InvoiceTemplate.GetInvoiceTemplatesById(id), "GET")
            }
        },
        Invoice: {
            async CreateInvoiceFromTemplate(templateId) {
                return await request(endpoints.Monetization.Invoice.CreateInvoiceFromTemplate(templateId), "POST")
            },
            async GetInvoiceById(id) {
                return await request(endpoints.Monetization.Invoice.GetInvoiceById(id), "GET")
            },
            async GetAllInvoicesSkipTake(skip, take) {
                return await request(endpoints.Monetization.Invoice.GetAllInvoicesSkipTake(skip, take), "GET")
            },
            async GetAllInvoicesByUserIdSkipTake(userId, skip, take) {
                return await request(endpoints.Monetization.Invoice.GetAllInvoicesByUserIdSkipTake(userId, skip, take), "GET")
            }
        }
    }
}