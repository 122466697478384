<template>
    <div class="player card card-dark">
        <div class="card-body">
            <div class="more-options">
                <button v-show="moreOptionsOpen" class="btn btn-secondary color-white" @click="showReportForm()">
                    <fai :icon="['fas', 'flag']"></fai>
                    Report
                </button>
                <button v-show="moreOptionsOpen && (isLoggedUsersAudio || isAdmin)" class="btn btn-secondary color-white" @click="editAudio()">
                    <fai :icon="['fas', 'pen']"></fai>
                    Edit
                </button>
                <button v-show="moreOptionsOpen && (isLoggedUsersAudio || isAdmin)" class="btn btn-secondary color-white" @click="statistics()">
                    <fai :icon="['fas', 'chart-simple']"></fai>
                    Analytics
                </button>
                <button class="btn btn-secondary color-white" @click="moreOptionsOpen = !moreOptionsOpen">
                    <fai :icon="['fas', moreOptionsOpen ? 'xmark' : 'ellipsis-vertical']"></fai>
                </button>
            </div>

            <div class="player-track-meta">
                <h1>
                    <span v-if="!audio.isPublic">
                        <fai :icon="['fas', 'lock']" title="Audio is private"></fai>
                        &nbsp;
                    </span>
                    {{ audio.name }}
                </h1>
                <h2 @click="goToProfile()" class="cursor-pointer"><span>{{ profile.name }}</span></h2>                
            </div>
            <div class="player-controls">
                <button v-if="audio.isInsidePlatform" :class="['btn', !isCurrentlyPlayingAudio || !isPlaying ? 'btn-primary' : 'btn-secondary', 'play-btn']" @click="playAudio()">
                    <span v-if="!isCurrentlyPlayingAudio || !isPlaying">Play <span v-if="audio.lengthSeconds > 0">{{ timeLabel }}</span></span>
                    <span v-else>Pause <span v-if="audio.lengthSeconds > 0">{{ timeLabel }}</span></span>
                </button>
                <button v-else class="btn btn-primary" @click="showRedirectToAudioOptions()">
                    Play
                </button>
            </div>
        </div>        
    </div>
</template>

<script>
    import requests from './../api/requests.js'
    import Swal from 'sweetalert2'
    import { secondsToTimeLabel, getThirdPartyFromEnum } from './../utils.js'

    export default {
        name: 'AudioPlayer',
        props: {
            audio: {
                default: null,
                type: Object
            },
            profile: {
                default: null,
                type: Object
            },
        },
        data() {
            return {
                moreOptionsOpen: false,
                goToFileInProgress: false,
                goToPageInProgress: false
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            },
            isLoggedUsersAudio() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.$store.getters.userData.userProfileId == this.profile.id
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            },  
            player() {
                return this.$store.getters.player
            },
            playerElement() {
                return document.getElementById('player')
            },
            isPlaying() {
                return this.player.isPlaying
            },
            isAudioLoaded() {
                return this.player.isAudioLoaded
            },
            isCurrentlyPlayingAudio() {
                return this.player.currentAudio.audio && this.player.currentAudio.audio.id == this.audio.id
            },
            timeLabel() {
                return secondsToTimeLabel(this.audio.lengthSeconds - 1)
            }
        },
        methods: {            
            async playAudio() {
                if (!this.player.currentAudio.audio || this.player.currentAudio.audio.id != this.audio.id) {
                    await this.getAudioFileUrl()
                }

                let audio = this.playerElement
                if (audio.paused) {
                    audio.play()
                    this.player.isPlaying = true
                } else {
                    audio.pause()
                    this.player.isPlaying = false
                }
            },            
            async getAudioFileUrl() {
                this.player.isAudioLoaded = false

                this.player.setAudio(this.audio)
                const response = await requests.UserContent.Audio.GetAudioFileUrl(this.audio.id)

                if (response.isSuccess) {
                    this.player.setFile(response.url)
                    this.player.isAudioLoaded = true
                } else {
                    this.error = response.error
                }
            },
            goToProfile() {
                const thirdParty = getThirdPartyFromEnum(this.profile.thirdParty)
                if (thirdParty) {
                    this.$router.push({ name: 'Profile ', params: { thirdParty: thirdParty, handle: this.profile.handleName } })
                } else {
                    this.$router.push({ name: 'Profile', params: { handle: this.profile.handleName } })
                }
            },
            showReportForm() {
                Swal.fire({
                    title: 'Report audio',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Report',
                    showLoaderOnConfirm: true,
                    background: "#262639",
                    color: "white",
                    customClass: {
                        confirmButton: 'dialog-btn-primary',
                        denyButton: 'dialog-btn-primary',
                        cancelButton: 'dialog-btn-secondary'
                    },
                    buttonsStyling: false,
                    preConfirm: async (text) => {
                        if (text.length <= 0) {
                            Swal.showValidationMessage(`Please tell us why you are reporting this audio.`)
                            return
                        }

                        const response = await requests.UserContent.Report.CreateReport(this.audio.id, text)

                        if (response.isSuccess) {
                            Swal.fire({
                                icon: 'success',
                                timer: 1000,
                                showConfirmButton: false,
                                background: "#262639",
                                color: "white"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something went wrong!',
                                background: "#262639",
                                color: "white"
                            })
                        }
                    }
                })
            },
            editAudio() {
                this.$router.push({ name: 'Edit audio', params: { id: this.audio.id } })
            },
            statistics() {
                this.$router.push({ name: 'Audio analytics', params: { id: this.audio.id } })
            },
            secondsToTime(seconds) {
                return secondsToTimeLabel(seconds)
            },
            showRedirectToAudioOptions() {
                let html = '<div style="display: flex; flex-flow: column; gap: 16px;">'

                html += '<span class="color-orange" style="font-size: 22px; margin-bottom: 6px;">This recording is outside AudioLove</span>'
                
                html += '<button id="goToFileBtn" class="btn btn-primary">Go to File</button>'
                html += '<span class="color-secondary">or</span>'
                html += '<button id="goToPageBtn" class="btn btn-primary" style="background: transparent !important; border: 2px var(--primary-color) solid !important; color: var(--primary-color) !important;">Go to Page</button>'
                
                html += '</div>'

                Swal.fire({
                    html: html,
                    focusConfirm: false,
                    background: "#262639",
                    color: "white",
                    showConfirmButton: false,
                    showCancelButton: false,
                    buttonsStyling: false,
                    didOpen: () => {
                        document.getElementById('goToFileBtn').onclick = this.goToFile
                        document.getElementById('goToPageBtn').onclick = this.goToPage
                    }
                })
            },
            async goToFile() {
                if (this.goToFileInProgress) {
                    return
                }

                this.goToFileInProgress = true

                const response = await requests.UserContent.Audio.GetAudioFileUrl(this.audio.id)

                if (response.isSuccess) {
                    const result = await requests.UserContent.Audio.ListenAudio(this.audio.id)

                    window.open(response.url, "_blank")
                    Swal.close()
                }

                this.goToFileInProgress = false
            },
            async goToPage() {
                if (this.goToPageInProgress) {
                    return
                }

                this.goToPageInProgress = true

                const response = await requests.UserContent.Audio.GetThirdPartyPageUrl(this.audio.id)

                if (response.isSuccess) {
                    const result = await requests.UserContent.Audio.ListenAudio(this.audio.id)

                    window.open(response.url, "_blank")
                    Swal.close()
                }

                this.goToPageInProgress = false
            }
        },
        async mounted() {
            requests.initialize(this.$store)
        }
    }
</script>

<style scoped>

    .player-track-meta h1 {
        margin: 0;
        color: var(--primary-color);
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        padding-bottom: 0.5rem;
        color: var(--primary-color)
    }

    .player-track-meta h2 {
        font-size: 16px;
        font-weight: 400;
        padding: 0 2px;
        position: relative;
        top: 1px;
        color: #ADADCB;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .player-controls {
        padding-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

        .player-controls button {
            width: 160px;
        }

    @media screen and (max-width: 60rem) {
        .player-controls button {
            width: 100%;
        }
    }

    .player-play-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        fill: var(--primary-color);
        transition: fill ease-in-out 0.3s;
    }

    .icon-container {
        fill: transparent;
        stroke: none;
    }

    .player-play-btn:hover {
        fill: #ba54f5;
    }

    .player-play-btn svg {
        color: var(--primary-color);
        position: relative;
        left: 0.5px;
        width: 36px;
        height: 36px;
        display: block;
    }

    .player-play-btn:hover svg {
        color: var(--primary-color);
    }

    .player-timeline {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        color: white;
    }

    .player-progress {
        display: flex;
        position: relative;
        height: 6px;
        background: #46424F;
        border-radius: 25px;
        margin: 0 5px;
        flex: 10;
        flex-basis: 100%;
        overflow: hidden;
    }

    .player-progress-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }

    .player-progress-filled {
        height: 6px;
        background: var(--primary-color);
        flex: 0;
        flex-basis: 0%;
        border-radius: 25px;
    }

    .player-time {
        padding: 2px 5px;
    }

    .player-volume-container {
        width: 30%;
    }

    .player-volume-container.large-controls {
        width: 15%;
    }

    .player-volume {
        height: 28px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
        background: transparent;
    }

        .player-volume:focus {
            outline: none;
        }

        .player-volume::-webkit-slider-runnable-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-webkit-slider-thumb {
            height: 16px;
            width: 16px;
            border-radius: 100px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -4px;
        }

        .player-volume:focus::-webkit-slider-runnable-track {
            background: var(--primary-color);
        }

        .player-volume::-moz-range-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-moz-range-thumb {
            height: 16px;
            width: 16px;
            border-radius: 100px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
            margin-top: -4px;
        }

        .player-volume::-ms-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-fill-lower {
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-fill-upper {
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-thumb {
            margin-top: 1px;
            height: 15px;
            width: 15px;
            border-radius: 5px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
        }

        .player-volume:focus::-ms-fill-lower {
            background: #38bdf8;
        }

        .player-volume:focus::-ms-fill-upper {
            background: #38bdf8;
        }

    .small-controls {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1rem;
    }

    @media screen and (min-width: 40rem) {
        .small-controls {
            display: none;
        }
    }

    @media screen and (max-width: 40rem) {
        .large-controls {
            display: none;
        }
    }

    .more-options {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        flex-flow: row;
        column-gap: 0.5rem;
    }

        .more-options button {
            min-width: 2.5rem;
            border: 1px gray solid !important;
        }   

    .play-btn {
        padding: 16px 24px 16px 24px;
        border-radius: 50px;
    }

    .play-btn.btn-secondary {
        border: 1px solid var(--primary-color) !important;
    }

    .audio-length {
        padding-right: 24px;
        color: #FFFFFF;
        font-size: 16px;
    }
</style>